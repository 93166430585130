<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
        <el-button v-if="!hideBack" class="right" type="text" @click="$router.back()">返回</el-button>
      </div>
      <el-date-picker v-model="searchForm.month" type="month" :clearable="false" @change="getByAbarnTestingTime"
        class="mgB10 custom-form-input" placeholder="请选择月份">
      </el-date-picker>
      <el-select v-model="searchForm.houseId" placeholder="请选择仓房" @change="getByAbarnTestingTime"
        class="mgL10 mgB10 custom-form-input">
        <el-option v-for="(item,index) in cangList" :key="index" :label="item.houseNo" :value="item.locaId">
        </el-option>
      </el-select>
      <el-select v-model="searchForm.id" placeholder="请选择测温数据" class="mgL10 mgB10 custom-form-input">
        <el-option v-for="(item,index) in tempList" :key="index" :label="item.tempTime" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchForm.circle" :disabled="!searchForm.isCircle" placeholder="请选择圈"
        class="mgL10 mgB10 custom-form-input">
        <el-option value="" label="全部"></el-option>
        <el-option v-for="(item,index) in circleNumber" :key="index" :label="'第'+item+'圈'" :value="index">
        </el-option>
      </el-select>
      <el-checkbox v-model="searchForm.isCircle" style="padding: 2px 15px 3px 10px;" label="按圈" border
        class="mgL10 mgB10" @change="changeIsCircle"></el-checkbox>
      <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
      </el-button>
      <el-button type="info" class="mgL10 mgB10" @click="detailVisible=true">详情</el-button>
      <router-link v-if="tempInfo.id" :to="{
       path: '/temp/pane',
       query: {
         id: tempInfo.id,
         time: tempInfo.tempTime,
         houseNoId: tempInfo.houseNoId
       }
      }">
        <el-button type="success" class="mgL10 mgB10">平面</el-button>
      </router-link>
      <div v-show="searchForm.isCircle" style="overflow: auto;">
        <div class="graphical-t" v-html="circleHtml"></div>
      </div>
      <div v-show="!searchForm.isCircle">
        <!-- <div class="left-inline"></div> -->
        <span :class="[maxHigh>0?'canvas':'']">
          <canvas id="canvas"></canvas>
          <div class="canvas-btns">
            <span v-for="(item,index) in maxHigh" :key="index">
              <!-- {{index}} -->
              <el-button :type="searchForm.high===index? 'primary':''" plain @click="changeHigh(index)">第{{item}}层
              </el-button>
            </span>
          </div>
        </span>
        <!-- <div class="right-inline"></div> -->
      </div>
    </el-card>
    <!-- 数据详情 -->
    <el-dialog class="custom-dialog" title="数据详情" :visible.sync="detailVisible" width="520px" :append-to-body="true">
      <TempDetail :tempInfo="tempInfo" />
    </el-dialog>
  </div>
</template>

<script>
  var moment = require('moment');
  import {
    getColorTemp,
    validateTempNo,
    group
  } from "@/common/index.js"
  export default {
    components: {
      TempDetail: () => import('@/views/temp/components/TempDetail')
    },
    data() {
      return {
        detailVisible: false,
        loading: false,
        searchForm: {
          month: new Date(),
          id: "",
          houseId: '',
          high: 0,
          row: 0,
          circle: '',
          isCircle: false
        },
        cangList: [],
        tempList: [],
        tempInfo: {},
        hideBack: true,
        radius: [],
        maxHigh: 0,
        circleNumber: 0,
        circleHtml: ""
      }
    },
    mounted() {
      this.searchForm.month = moment(this.$route.query.time).format('YYYY-MM')
      let id = this.$route.query.id
      if (id) {
        this.searchForm.id = Number(id)
        this.hideBack = false
      }
      let houseNoId = this.$route.query.houseNoId
      if (houseNoId) this.searchForm.houseId = Number(houseNoId)
      this.search();
      this.getAbarnNames();
      this.getByAbarnTestingTime()
    },
    methods: {
      //是否按圈
      changeIsCircle() {
        this.search();
      },
      //更改层
      changeHigh(e) {
        this.searchForm.high = e
        this.solidByIdAbarnHigh()
      },
      //搜索
      search() {
        if (this.searchForm.id) {
          this.byIdAbarn();
          // isRow ? this.solidByIdAbarnRow() : this.solidByIdAbarnHigh()
        }
      },
      //获取仓房信息
      getAbarnNames() {
        this.$axios.Get(this.$api.byCangtypeLiangLocaidNo + '?' + encodeURIComponent("cangType[]") + "=" + [1, 2]
          .toString(), {}).then(res => {
          this.cangList = res.data
        })
      },
      //获取测温数据
      async getByAbarnTestingTime() {
        let res = await this.$axios.Get(this.$api.byAbarnTestingTime, {
          "locaId": this.searchForm.houseId,
          "month": moment(this.searchForm.month).format('YYYY-MM')
        })
        this.tempList = res.data
      },
      //获取仓房信息
      async byIdAbarn() {
        let res = await this.$axios.Get(this.$api.byIdAbarn + this.searchForm.id, {})
        res.data['testingTime'] = res.data.tempTime
        this.tempInfo = res.data
        this.searchForm.high = 0
        this.circleNumber = res.data.row * 1
        if (this.searchForm.isCircle) {
          this.searchForm.circle || this.searchForm.circle === 0 ? this.byIdTableHighOrCircle() : this
            .testingTempByIdTable()
        } else {
          this.solidByIdAbarnHigh()
        }
      },
      //按圈查看全部
      testingTempByIdTable() {
        this.loading = true
        this.$axios.Get(this.$api.testingTempByIdTable + this.searchForm.id, {}).then(res => {
          let hHtml = "";
          for (let i = 0; i < res.data.col; i++) hHtml += "<div data-col='" + i + "' class='qyzn-high'>第" + (i +
              1) +
            "根</div>";
          document.querySelector(".graphical-t").style.width = (res.data.col * 50 + 40) + "px";
          hHtml += "<br>"
          // $(".").html(hHtml + "<br>").css("width",);
          for (let i = 0; i < res.data.col * 1; i++) {
            let aHtml = "<div class='qyzn-col' style='display: inline-grid;'>";
            for (let k = 0; k < res.data.high * 1; k++) {
              if (res.data.temps[k][i] !== 'k' && res.data.temps[k][i]) {
                aHtml += "<div class='qyzn-block'></div><div class=' qyzn-data' style='background-color: " +
                  getColorTemp(res.data.temps[k][i]).bgColor + ";'>" + validateTempNo(res.data.temps[k][i]) +
                  "</div>";
              }
            }
            hHtml += aHtml + "<div class='qyzn-bottom-bottom'></div></div>";
          }
          this.circleHtml = hHtml
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      //按圈查看
      byIdTableHighOrCircle() {
        this.$axios.Get(this.$api.byIdTableHighOrCircle, {
          high: this.searchForm.circle,
          id: this.searchForm.id
        }).then(res => {
          let cols = res.data.col.split("#"),
            hHtml = "";
          for (let i = 0; i < cols[this.searchForm.circle]; i++) {
            hHtml += "<div data-col='" + i + "' class='qyzn-high'>第" + (i + 1) + "根</div>"
          }

          document.querySelector(".graphical-t").style.width = (cols[this.searchForm.circle] * 50 + 50) + "px"
          hHtml += "<br>"
          let arrays = group(res.data.temps, cols[this.searchForm.circle]);
          for (let i = 0; i < cols[this.searchForm.circle]; i++) {
            let aHtml = "<div class='qyzn-col'>";
            for (let j in arrays) {
              if (arrays[j][i] !== 'k') {
                aHtml += "<div class='qyzn-block'></div><div class=' qyzn-data' style='background-color: " +
                  getColorTemp(arrays[j][i]).bgColor + ";'>" + validateTempNo(arrays[j][i]) + "</div>"
              }
            }
            hHtml += aHtml + "<div class='qyzn-bottom-bottom'></div></div>"
          }

          this.circleHtml = hHtml
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      //获取层数据
      solidByIdAbarnHigh() {
        this.loading = true
        this.$axios.Get(this.$api.solidByIdAbarnHigh, {
          high: this.searchForm.high,
          id: this.searchForm.id
        }).then(res => {
          this.renderingPage(res.data, this.tempInfo);
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      //渲染
      renderingCanva(numOne, tempInfo) {
        var canvas = document.getElementById("canvas");
        var ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 800;
        ctx.beginPath();
        ctx.fillStyle = "rgba(255, 255, 255, 0)";
        ctx.strokeStyle = "#0000ff";
        ctx.ellipse(250, 125, 250, 125, 0, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.fillStyle = '#FFFAFA';
        ctx.strokeStyle = "#8c908a";
        ctx.ellipse(250, 675, 250, 125, 0, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.translate(0, numOne);
        ctx.fillStyle = "#b1dcfbeb";
        ctx.shadowColor = "black";
        ctx.shadowBlur = 20;
        ctx.strokeStyle = "#0000ff";
        ctx.ellipse(250, 125, 250, 125, 0, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();
        var rowX = Math.ceil(250 / (tempInfo.row * 1 + 1));
        var rowY = Math.ceil(125 / (tempInfo.row * 1 + 1));
        let radius = []
        for (let i = 0; i < tempInfo.row * 1 + 1; i++) {
          ctx.beginPath();
          ctx.ellipse(250, 125, (250 - rowX * i), (125 - rowY * i), 0, 0, Math.PI * 2);
          radius.push({
            x: 250 - rowX * i,
            y: 125 - rowY * i
          })
          ctx.setLineDash([5, 15]);
          ctx.lineWidth = 1;

          ctx.stroke();
        }

        this.radius = radius;
      },
      renderingPage(datas, tempInfo) {
        //start  获取最大层数
        var highMax = 0;
        var highs = tempInfo.high.split("#")
        highs.filter(item => item)
        for (let i in highs) {
          highMax = highMax < highs[i] ? highs[i] : highMax;
        }
        this.maxHigh = highMax * 1
        //end

        //start 根据层数生成按钮
        // var buttonHtml = "";
        // var buttonMB = parseInt((550 - 25 * highMax) / (highMax - 1));
        // for (var i = 0; i < highMax; i++) {
        //     buttonHtml += "<button type='button' class='layui-btn layui-btn-primary' data-id='" + i + "' style=' margin-bottom:" + buttonMB + "px; margin-left:10px;height: 25px;line-height: 25px;background-color:" + ((high == i) ? " #1E9FFF" : "") + "'>第" + (i * 1 + 1) + "层</button>"
        // }
        // $(".btn-high").html(buttonHtml);
        //end

        //start 生成圆柱体样式
        var moveDown = Math.round((800 - 250) / highMax * (this.searchForm.high));
        this.renderingCanva((this.searchForm.high * 1 + 1) == highMax * 1 ? 550 : moveDown, tempInfo);
        //end

        //start 添加数据
        var canvas = document.getElementById("canvas");
        var ctx = canvas.getContext('2d');

        var cols = tempInfo.col.split("#");
        cols.filter(item => item)
        var colCount = 0;
        var arrayCol = [];
        for (var i in cols) {
          arrayCol.push(datas.slice(colCount, cols[i] * 1 + colCount));
          colCount += cols[i] * 1;
        }

        var count = 0;
        for (let i = this.radius.length - 1; i >= 0; i--) {
          if (i === 0) break;
          var array = arrayCol[count];

          var angle = parseInt(360 * 2 / array.length);
          var positive = 0;
          var negative = 0;
          for (let j in array) {
            ctx.beginPath();
            ctx.setLineDash([0, 0]);
            ctx.shadowBlur = 0;
            ctx.textAlign = "center";
            ctx.strokeStyle = '#333';
            ctx.fillStyle = getColorTemp(array[j]).bgColor;
            var sum88 = j * 1 + 1;
            for (let k = 0; k <= count; k++) sum88 += arrayCol[k].length;
            sum88 = sum88 - arrayCol[count].length;
            var text = validateTempNo(array[j]) + "(" + sum88 + ")";
            if (j < (array.length / 2)) {
              ctx.ellipse(250 - this.radius[i].x * Math.cos(angle * positive * Math.PI / 360), 125 - this.radius[i]
                .y *
                Math.sin(
                  angle * positive * Math.PI / 360), 20, 10, 0, 0, Math.PI * 2);
              ctx.stroke();
              ctx.closePath();
              ctx.fill();
              ctx.strokeStyle = '#333';
              ctx.strokeText(text, 250 - this.radius[i].x * Math.cos(angle * positive * Math.PI / 360), 125 - this
                .radius[i].y *
                Math.sin(angle * positive * Math.PI / 360) + 3);
              positive++;
            } else {
              if (array.length / 2 === array.length - Math.ceil(array.length / 2)) {
                ctx.ellipse(250 + this.radius[i].x * Math.cos(angle * negative * Math.PI / 360), 125 + this.radius[
                    i]
                  .y * Math
                  .sin(angle * negative * Math.PI / 360), 20, 10, 0, 0, Math.PI * 2);
                ctx.stroke();
                ctx.closePath();
                ctx.fill();
                ctx.strokeStyle = '#333';
                ctx.strokeText(text, 250 + this.radius[i].x * Math.cos(angle * negative * Math.PI / 360), 125 + this
                  .radius[i].y *
                  Math.sin(angle * negative * Math.PI / 360) + 3);
              } else {
                ctx.ellipse(250 + this.radius[i].x * Math.cos((angle * negative * Math.PI + angle * 2) / 360), 125 +
                  this.radius[
                    i].y * Math.sin((angle * negative * Math.PI + angle * 2) / 360), 20, 10, 0, 0, Math.PI * 2);
                ctx.stroke();
                ctx.closePath();
                ctx.fill();
                ctx.strokeStyle = '#333';
                ctx.strokeText(text, 250 + this.radius[i].x * Math.cos((angle * negative * Math.PI + angle * 2) /
                    360),
                  125 +
                  this.radius[i].y * Math.sin((angle * negative * Math.PI + angle * 2) / 360) + 3);
              }
              negative++;
            }

          }
          count++;
        }
      }

    }
  }
</script>
<style>
  .qyzn-high {
    width: 40px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    line-height: 20px;
    background-color: #8dceff;
    margin: 5px;
    font-size: 12px;
    border-radius: 5px;
  }

  .qyzn-data {
    height: 20px;
    line-height: 20px;
    /*background-color: chartreuse;*/
    display: block;
    margin: 0px 5px;
    border-radius: 45%;
    border: 1px #8d8d8d solid;
    font-size: 13px;
  }

  .qyzn-col {
    display: inline-block;
    width: 50px;
  }

  .qyzn-block {
    height: 10px;
    border: 1px #c9c5c5 solid;
    width: 0.5px;
    background-color: #c9c5c5;
    margin: 0 24px;
  }

  .qyzn-bottom-bottom {
    height: 10px;
    border: 1px #8d8d8d solid;
    width: 1px;
    background-color: #8d8d8d;
    margin: 0 24px;
    border-radius: 0px 0px 20px 20px;
  }

  .graphical-t {
    text-align: center;
    margin: auto;
  }
</style>
<style scoped lang="less">
  .canvas {
    position: relative;
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);

    &-btns {
      position: absolute;
      left: 100%;
      bottom: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        margin-left: 15px;
      }

      span+span {
        margin-top: 10px;
      }
    }
  }

  .canvas::before {
    position: absolute;
    content: "";
    left: 0;
    height: 550px;
    bottom: 125px;
    top: 125px;
    width: 2px;
    background-color: #9e9e9e;
  }

  .canvas:after {
    position: absolute;
    content: "";
    right: 0;
    height: 550px;
    bottom: 125px;
    top: 125px;
    width: 2px;
    background-color: #9e9e9e;
  }
</style>
